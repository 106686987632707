import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Nosh Bags routing
const Orders = Loadable(lazy(() => import('views/orders/Orders')));
const AddOrder = Loadable(lazy(() => import('views/status/ComingSoon')));
const AddReceipt = Loadable(lazy(() => import('views/status/ComingSoon')));

// ==============================|| MAIN ROUTING ||============================== //

const OrderRoutes = {
    path: '/orders',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Orders />
        },
        {
            path: '/add',
            element: <AddOrder />
        },
        {
            path: 'receipt/add',
            element: <AddReceipt />
        }
    ]
};

export default OrderRoutes;
