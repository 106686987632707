// assets
import { IconListDetails, IconRowInsertBottom, IconFilePlus } from '@tabler/icons';

// constant
const icons = { IconListDetails, IconRowInsertBottom, IconFilePlus };

// ==============================|| ORDERS MENU ITEMS ||============================== //

const orders = {
    id: 'orders',
    type: 'group',
    title: 'Orders',
    children: [
        {
            id: 'orders-view',
            title: 'Orders',
            type: 'item',
            url: '/orders',
            icon: icons.IconListDetails,
            breadcrumbs: true
        },
        {
            id: 'order-add',
            title: 'Add Order',
            type: 'item',
            url: '/orders/add',
            icon: icons.IconRowInsertBottom,
            breadcrumbs: true
        },
        {
            id: 'order-receipt',
            title: 'Create Receipt',
            type: 'item',
            url: '/orders/receipt/add',
            icon: icons.IconFilePlus,
            breadcrumbs: true
        }
    ]
};

export default orders;
