import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
        // {
        //     path: '/',
        //     element: <Navigate to="/dashboard" replace />
        // },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        }
    ]
};

export default MainRoutes;
