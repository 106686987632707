import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Nosh Bags routing
const NoshBags = Loadable(lazy(() => import('views/noshBags/NoshBags')));
const AddNoshBag = Loadable(lazy(() => import('views/noshBags/AddNoshBag')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/nosh-bags',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <NoshBags />
        },
        {
            path: '/add',
            element: <AddNoshBag />
        }
    ]
};

export default MainRoutes;
