// assets
import { IconLayoutGridAdd, IconPaperBag } from '@tabler/icons';

// constant
const icons = { IconLayoutGridAdd, IconPaperBag };

// ==============================|| products MENU ITEMS ||============================== //

const products = {
    id: 'products',
    type: 'group',
    title: 'Products',
    children: [
        {
            id: 'products-bag',
            title: 'Nosh Bags',
            type: 'item',
            url: '/nosh-bags',
            icon: icons.IconPaperBag,
            breadcrumbs: true
        },
        {
            id: 'products-add',
            title: 'Add Bag',
            type: 'item',
            url: '/nosh-bags/add',
            icon: icons.IconLayoutGridAdd,
            breadcrumbs: true
        }
    ]
};

export default products;
