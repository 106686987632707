import dashboard from './dashboard';
import products from './products';
import orders from './orders';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, products, orders]
};

export default menuItems;
