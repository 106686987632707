// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isAuthenticated: false, // for active default menu
    user: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITLIALIZE:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user
            };
        case actionTypes.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user
            };
        case actionTypes.REGISTER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        default:
            return state;
    }
};

export default authReducer;
